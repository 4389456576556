import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Login from '../views/auth/login.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Dashboard',
		component: () => import('../views/dashboard/index.vue'),
		meta: {
			auth: true
		}
	},
	{
		path: '/categories',
		name: 'Categoreis',
		component: () => import('../views/products/categories.vue'),
		meta: {
			auth: true
		}
	},
	{
		path: '/brands',
		name: 'Brands',
		component: () => import('../views/products/brands.vue'),
		meta: {
			auth: true
		}
	},
	{
		path: '/barcode',
		name: 'Barcode',
		component: () => import('../views/products/barcode.vue'),
		meta: {
			auth: true
		}
	},
	{
		path: '/settings',
		component: () => import('../views/settings/index.vue'),
		meta: {
			auth: true,
		},
		children: [
			{
				path: '',
				name: 'Settings',
				component: () => import('../views/settings/company.vue'),
				meta: {
					auth: true
				}
			},
			{
				path: 'shippings',
				name: 'Shippings',
				component: () => import('../views/settings/shipping.vue'),
				meta: {
					auth: true
				}
			},
			{
				path: 'bank-accounts',
				name: 'Bank accounts',
				component: () => import('../views/settings/bank.vue'),
				meta: {
					auth: true
				}
			},
			{
				path: 'shops',
				name: 'Shops',
				component: () => import('../views/settings/shops.vue'),
				meta: {
					auth: true
				}
			},
			{
				path: 'marketplaces',
				name: 'Marketplaces',
				component: () => import('../views/settings/marketplaces.vue'),
				meta: {
					auth: true
				}
			},
			{
				path: 'user-management',
				name: 'User Management',
				component: () => import('../views/settings/users.vue'),
				meta: {
					auth: true
				}
			},
			// {
			// 	path: 'general-setup',
			// 	name: 'General Setup',
			// 	component: GeneralSetup,
			// 	meta: {
			// 		auth: true
			// 	}
			// }

		]
	},
	{
		path: '/employee',
		component: () => import('../views/employee/index.vue'),
		meta: {
			auth: true
		},
		children: [
			{
				path: '',
				component: () => import('../views/employee/employee.vue'),
				name: 'Employee',
				meta: {
					auth: true
				}
			},
			{
				path: 'create',
				component: () => import('../views/employee/createEmployee.vue'),
				name: 'Create Employee',
				meta: {
					auth: true
				}
			},
			{
				path: 'detail/:id',
				component: () => import('../views/employee/detailEmployee.vue'),
				meta: {
					auth: true
				},
				children: [
					{
						path: '',
						component: () => import('../views/employee/personalData.vue'),
						name: 'Personal data',
						meta: {
							auth: true
						},
					},
					{
						path: 'employment-data',
						name:'Employment Data',
						component: () => import('../views/employee/employmentData.vue'),
						meta: {
							auth: true
						}
					},
					{
						path: 'payroll',
						nema: 'Employment Payroll',
						component: () => import('../views/employee/payroll.vue'),
						meta: {
							auth: true
						}
					},
					{
						path: 'fingerprint',
						name:'Fingerprint',
						component: () => import('../views/employee/fingerprint.vue'),
						meta: {
							auth: true
						}
					}
				]
			}
		]
	},
	{
		path: '/time-management',
		component: () => import('../views/timeManagement/index.vue'),
		meta: {
			auth: true
		},
		children: [
			{
				path: '',
				name: 'Time Management',
				component: () => import('../views/timeManagement/attlog.vue'),
				meta: {
					auth: true
				}
			},{
				path: 'stats',
				name: 'Employee Stats',
				component: () => import('../views/timeManagement/stats.vue'),
				meta: {
					auth: true
				}
			},{ 
				path: 'payroll',
				name: 'Employee Payroll',
				component: () => import('../views/timeManagement/payroll.vue'),
				meta: {
					auth: true
				}
			}
		]
	},
	{
		path: '/suppliers',
		component: () => import('../views/supplier/supplier.vue'),
		name: 'Supplier',
		meta: {
			auth: true
		}
	},
	{
		path: '/products',
		component: () => import('../views/products/index.vue'),
		meta: {
			auth: true
		},
		children: [
			{
				path: '',
				name: 'Products',
				component: () => import('../views/products/products.vue'),
				meta: {
					auth: true
				}
			},
			{
				path: 'create',
				name: 'Create product',
				component: () => import('../views/products/productCreate.vue'),
				meta: {
					auth: true
				}
			},
			{
				path: 'edit/:id',
				name: 'Edit Product',
				component: () => import('../views/products/productEdit.vue'),
				meta: {
					auth: true
				}
			}, 
			{
				path: 'detail/:id',
				name: 'Detail Product',
				component: () => import('../views/products/productDetail.vue'),
				meta: {
					auth: true
				}
			}
		]
	},
	{
		path: '/receipts',
		component: () => import('../views/inventory/receipts/index.vue'),
		meta: {
			auth: true
		},
		children: [
			{
				path: '',
				component: () => import('../views/inventory/receipts/receipts.vue'),
				name: 'Receipts',
				meta: {
					auth: true
				}
			},
			{
				path: 'create',
				component: () => import('../views/inventory/receipts/create.vue'),
				name: 'Create Receipts',
				meta: {
					auth: true
				}
			},
			{
				path: 'detail/:id',
				component: () => import('../views/inventory/receipts/detail.vue'),
				name: 'Detail Receipts',
				meta: {
					auth: true
				}
			}
		]
	},
	{
		path: '/stock-opname',
		component: () => import('../views/inventory/stockopname/index.vue'),
		meta: {
			auth: true
		},
		children: [
			{
				path: '',
				name: 'Stock Opname',
				component: () => import('../views/inventory/stockopname/stockopname.vue'),
				meta: {
					auth: true
				}
			},
			{
				path: 'detail/:id',
				name: 'Detail Stockopname',
				component: () => import('../views/inventory/stockopname/detail.vue'),
				meta: {
					auth: true
				}
			}
		]
	},
	{
		path: '/stock-transfers',
		component: () => import('../views/inventory/transfer/index.vue'),
		meta: {
			auth: true
		},
		children: [
			{
				path: '',
				name: 'Transfer',
				component: () => import('../views/inventory/transfer/transfer.vue'),
				meta: {
					auth: true
				}
			},
			{
				path: 'create',
				name: 'Create Transfer',
				component: () => import('../views/inventory/transfer/create.vue'),
				meta: {
					auth: true
				}
			},
			{
				path:'detail/:id',
				name: 'Detail Transfer',
				component: () => import('../views/inventory/transfer/detail.vue'),
				meta: {
					auth: true
				}
			}
		]
	},
	{
		path: '/statistics',
		component: () => import('../views/statistics/index.vue'),
		meta: {
			auth: true
		},
		children: [
			{
				path: '',
				name: 'Sales Statistics',
				component: () =>  import('../views/statistics/sales.vue'),
				meta: {
					auth: true
				}
			},
			{
				path: 'items',
				name: 'Itme Statistics',
				component: () => import('../views/statistics/items.vue'),
				meta: {
					auth: true
				}
			},
			{
				path: 'incoming',
				name: 'Receipts Statistics',
				component: () => import('../views/statistics/receipts.vue'),
				meta: {
					auth: true
				}
			}
		]
	},
	{
		path: '/login',
		component: Login,
		name: 'Login'
	},
	{
		path: '/resetPassword/:token',
		component: () => import('../views/auth/reset.vue')
	},
	{
		path: '/unaithorized',
		name: 'Unauthorized',
		component: () => import('../views/error/Unauthorized.vue'),
		meta: {
			auth: true
		}
	}
  
]

const router = new VueRouter({
	scrollBehavior () {
		return {x: 0, y: 0, behavior: 'smooth'}
	},
	linkActiveClass: 'active',
	linkExactActiveClass: 'active',
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

router.beforeEach((to, from, next) => {
	const tokenLocalStorage = localStorage.getItem('token')
	const userId = localStorage.getItem('userId')
	if(to.meta.auth && !store.getters['auth/token'] || to.meta.auth && !tokenLocalStorage || to.meta.auth && !userId) next({name: 'Login'})
	else if(!to.meta.auth && store.getters['auth/token'] && tokenLocalStorage && userId) next({name: 'Dashboard'})
	else next()
})

export default router
